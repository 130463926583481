import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import "./rewards.scss";
import { rewardsService } from "../../services/rewards.service";
import swal from "sweetalert";

export function ItemRewardCupon(props) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [btnSubmit,setBtnSubmit] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const redeemCoupon = () => {
    setBtnSubmit(true);
    swal({
      title: "¿Estás seguro que deseas obtener este cupón?",
      text: props.title + " - " + props.points + " Puntos",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancelar", "Si, aceptar"],
    }).then((willDelete) => {
      if (willDelete) {
        rewardsService
          .redeemCoupon({ RewardProductFilterId: props.rewardProductFilterId })
          .then((result) => {
            if (result) {
              setBtnSubmit(false);
              props.setCategoryActive(0);
              props.getRewardProducts(0);
            }
          });
      }
    });
  };

  return (
    <div className="rewards-card">
      <div className="coupon">
        <div className="coupon-thumb">
          <img src={props.urlImage} alt="product" />
        </div>
        <div className="coupon-content">
          <div className="coupon-thumb-text">{props.title}</div>
          <div className="footer">
            <div>
              <p className="category">{props.rewardCategoryDescription}</p>
              <p className="points">{props.points} Puntos</p>
            </div>
            <div>
              {props.reward >= props.points ? (
                <>
                  <button
                    id={"Tooltip" + props.rewardProductFilterId}
                    onClick={redeemCoupon}
                    disabled={btnSubmit}
                  >
                    <i className="icon-download-file"></i>
                  </button>
                  <Tooltip
                    placement="bottom"
                    isOpen={tooltipOpen}
                    target={"Tooltip" + props.rewardProductFilterId}
                    toggle={toggle}
                  >
                    Descargar
                  </Tooltip>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
